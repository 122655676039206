<template>
    <CCardGroup style="height: 100vh;">
        <CCard
            class="background-image"
        >
        </CCard>
        <CCard class="p-4">
            <CCardBody>
                <CForm @submit.prevent="login">
                    <CRow class="justify-content-center">
                        <CCol md="10" sm="12" style="margin:50px" class="text-center">
                            <img src="@/assets/images/logo_nomina.png" align="center" width="30%"/>
                        </CCol>
                        <CCol md="10" sm="12">
                            <h1>{{$t('login.label')}}</h1>
                            <p class="text-muted">{{$t('login.sigin')}}</p>
                            <CInput
                                type="email"
                                v-model="email"
                                :placeholder="$t('login.input.email.placeholder')"
                                required="required"
                                :title="$t('login.input.email.required')"
                            >
                                <template #prepend-content><CIcon name="cil-user"/></template>
                            </CInput>
                            <CInput
                                v-model="password"
                                :placeholder="$t('login.input.password.placeholder')"
                                type="password"
                                required="required"
                                :title="$t('login.input.password.required')"
                            >
                                <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                            </CInput>
                            <CRow>
                                <CCol col="6" class="text-left">
                                    <CButton 
                                        type="submit" 
                                        color="primary" 
                                        class="px-4"
                                    >{{$t('login.entry')}}</CButton>
                                </CCol>
                                <CCol col="6" class="text-right">
                                    <CButton color="link" class="px-0">{{$t('login.forgotePassword')}}</CButton>
                                </CCol>
                            </CRow>
                        </CCol>
                    </CRow>
                </CForm>
            </CCardBody>
        </CCard>
    </CCardGroup>
</template>

<script>
import axios from "axios";
import store from '../store'

export default {
    name: 'Login',
    data() {
        return {
            email: '',
            password: ''
        }
    },
    methods: {
        login() {
            axios.post('/api/login', {
                email: this.email,
                password: this.password,
                device: "web",
            }).then(response => {
                axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
                store.dispatch('setUser', response.data);
                this.$router.push({ path: '/global-payroll' });
            }).catch(error => {
                this.$toast.error(error.response.data.message);
            });
        }
    }
}
</script>

<style>
    .background-image {
        background-image: url('~@/assets/images/background.jpg');
        background-size: cover;
    }
</style>
